<template>
  <div>
    <patient-edit />
    <form-finish />
  </div>
</template>

<script>
import patientEdit from '../Patient-edit.vue'
import formFinish from '../../form/other/Form-other-finish.vue'

export default ({
  components: {
    patientEdit,
    formFinish,
  },

})
</script>
