<template>

  <div>

    <b-card
      no-body
      class="mb-2 pb-2 pl-2 pr-2"
    >
      <div class="mt-2 d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb :items="items" />
      </div>

      <form-abstract />

      <b-col
        cols="12"
        class="mt-2"
      >
        <b-button
          type="submit"
          variant="outline-primary"
          @click="cancel"
        >
          Cancelar
        </b-button>
        <b-button
          type="button"
          variant="outline-primary"
          class="ml-2"
          @click="previous"
        >
          Voltar
        </b-button>

        <b-button
          type="submit"
          variant="primary"
          class="ml-2"
          @click="next"
        >
          Adicionar
        </b-button>

      </b-col>

    </b-card>

  </div>

</template>

<script>
import {
  BCard, BBreadcrumb, BCol, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import formAbstract from './Form-other-abstract.vue'

export default {
  components: {
    BCard,
    BBreadcrumb,
    BCol,
    BButton,

    formAbstract,

  },
  directives: {
    Ripple,
  },

  data() {
    return {
      link: '',
      items: [
        {
          text: 'Medicamento',
          active: true,
        },
        {
          text: 'Horário',
          active: true,
        },
        {
          text: 'Frequência',
          active: true,
        },
        {
          text: 'Resumo',
          active: false,
        },
      ],
    }
  },

  mounted() {
    this.link = this.$store.getters['formOther/link']
  },

  methods: {
    next() {
      this.$store.dispatch('formOther/save')
        .then(() => {
          this.$swal({
            title: 'Lembrete enviado com sucesso!',
            text: 'Agora, seu paciente será notificado sobre o novo lembrete.',
            icon: 'success',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          })
            .then(result => {
              if (result.isConfirmed) {
                this.$store.commit('patient/UPDATE_SHOW_TAB', true)
                this.$router.push('patient-edit')
              }
            })
        },
        () => {
          this.$swal({
            title: 'Erro!',
            text: 'O lembrete não foi cadastrado',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          }).then(result => {
            if (result.isConfirmed) {
              this.$router.push(this.link)
            }
          })
        })
    },
    cancel() {
      this.$store.commit('patient/UPDATE_SHOW_TAB', true)
      this.$router.push('patient-edit')
    },
    previous() {
      this.$router.push(`${this.link}-frequency`)
    },
  },
}
</script>
